<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-card>
                  <v-card-title class="h6">Proveedor</v-card-title>
                  <v-card-text>
                    <v-row>
                      <!-- Proveedor -->
                      <v-col cols="4" sm="5" md="5" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Proveedor"
                          v-model="proveedor"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- CUIT -->
                      <v-col cols="4" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="CUIT"
                          v-model="cuit"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Tipo IVA -->
                      <v-col cols="4" sm="4" md="4" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Tipo IVA"
                          v-model="tipoIva"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Dirección -->
                      <v-col cols="4" sm="5" md="5" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Dirección"
                          v-model="direccion"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Modalidad de pago -->
                      <v-col cols="4" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Modalidad de pago"
                          v-model="modalidadPago"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Destino de pago -->
                      <v-col cols="4" sm="4" md="4" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Destino de pago"
                          v-model="destinoPago"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-card>
                  <v-card-title class="h6">Comprobante</v-card-title>
                  <v-card-text>
                    <v-row>
                      <!-- Tipo de comprobante -->
                      <v-col cols="4" sm="5" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Tipo de comprobante"
                          v-model="tipoComprobante"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Comprobante numero -->
                      <v-col cols="4" sm="4" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Comprobante número"
                          v-model="comprobanteNum"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Fecha -->
                      <v-col cols="3" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Fecha"
                          v-model="fecha"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Fecha contable -->
                      <v-col cols="3" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Fecha contable"
                          v-model="fechaContable"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <!-- Fecha vencimiento -->
                      <v-col cols="3" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Fecha de vencimiento"
                          v-model="fechaVencimiento"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- numero de CAE -->
                      <v-col cols="3" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Número de CAE"
                          v-model="numeroCAE"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- fecha de CAE -->
                      <v-col cols="3" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Fecha vencimiento CAE"
                          v-model="fechaVencCAE"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Tipo de emision -->
                      <v-col cols="3" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Tipo de emisión"
                          v-model="tipoEmision"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-card>
                  <v-card-title class="h6">
                    Datos del comprobante
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <!-- Neto -->
                      <v-col cols="3" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          prepend-inner-icon="mdi-currency-usd"
                          class="right-input"
                          readonly
                          label="Neto"
                          v-model="importeNeto"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- IVA -->
                      <v-col cols="3" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          prepend-inner-icon="mdi-currency-usd"
                          label="IVA"
                          v-model="iva"
                          class="right-input"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Percepción IVA -->
                      <v-col cols="3" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          prepend-inner-icon="mdi-currency-usd"
                          label="Percepción IVA"
                          class="right-input"
                          v-model="percepcionIva"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Percepción IIBB -->
                      <v-col cols="3" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          prepend-inner-icon="mdi-currency-usd"
                          readonly
                          class="right-input"
                          label="Percepción IIBB"
                          v-model="percepcionIIBB"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Impuestos internos -->
                      <v-col cols="4" sm="4" md="4" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          prepend-inner-icon="mdi-currency-usd"
                          filled
                          readonly
                          class="right-input"
                          label="Impuestos internos"
                          v-model="impuestosInternos"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Otros conceptos -->
                      <v-col cols="4" sm="4" md="4" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          prepend-inner-icon="mdi-currency-usd"
                          dense
                          filled
                          readonly
                          class="right-input"
                          label="Otros conceptos"
                          v-model="otrosConceptos"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Total -->
                      <v-col cols="4" sm="4" md="4" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          prepend-inner-icon="mdi-currency-usd"
                          class="right-input"
                          label="Total"
                          v-model="importeTotal"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="this.haveComprobCancel">
        <v-card>
          <v-card-title class="h6 primary--text">
            Listado de comprobantes cancelados
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-data-table
                  :headers="headersComprobantesCancelados"
                  :items="comprobantesCanceladosList"
                  class="elevation-1"
                  hide-default-footer
                  :items-per-page="999"
                  dense
                >
                </v-data-table
              ></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="this.haveListRetenciones">
        <v-card>
          <v-card-title class="h6 primary--text">
            Listado de retenciones
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-data-table
                  :headers="headersRetenciones"
                  :items="retencionesList"
                  :items-per-page="999"
                  class="elevation-1"
                  hide-default-footer
                  dense
                >
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="this.haveListValores">
        <v-card>
          <v-card-title class="h6 primary--text">
            Listado de valores
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-data-table
                  :headers="headersValores"
                  :items="valoresList"
                  class="elevation-1"
                  :items-per-page="999"
                  hide-default-footer
                  dense
                >
                </v-data-table
              ></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import { mapActions } from "vuex";
import GoBackBtn from "@/components/shared/GoBackBtn";
export default {
  name: "DetalleComprobanteProv",
  components: {
    PageHeader,
    GoBackBtn,
  },
  created() {
    this.setDetalleComprobante();
  },
  data() {
    return {
      routeToGo: "CuentaCorrienteProveedores",
      proveedor: null,
      cuit: null,
      tipoIva: null,
      direccion: null,
      modalidadPago: null,
      destinoPago: null,
      tipoComprobante: null,
      comprobanteNum: null,
      fecha: null,
      fechaContable: null,
      fechaVencimiento: null,
      fechaVencCAE: null,
      numeroCAE: null,
      tipoEmision: null,
      importeNeto: null,
      iva: null,
      percepcionIva: null,
      percepcionIIBB: null,
      impuestosInternos: null,
      otrosConceptos: null,
      importeTotal: null,
      haveComprobCancel: false,
      haveListRetenciones: false,
      haveListValores: false,
      comprobantesCanceladosList: [],
      headersComprobantesCancelados: [
        {
          text: "Fecha",
          value: "fecha",
          sortable: false,
          align: "start",
        },
        {
          text: "Tipo comprobante",
          value: "tipoCompNombre",
          sortable: false,
          align: "start",
        },
        {
          text: "Comprobante",
          value: "compNro",
          sortable: false,
          align: "start",
        },
        {
          text: "Total",
          value: "total",
          sortable: false,
          align: "end",
        },
        {
          text: "Cancelado",
          value: "importeCancelado",
          sortable: false,
          align: "end",
        },
        {
          text: "Saldo",
          value: "saldo",
          sortable: false,
          align: "end",
        },
        {
          text: "Cancelado O.P.",
          value: "canceladoOP",
          sortable: false,
          align: "end",
        },
      ],
      retencionesList: [],
      headersRetenciones: [
        {
          text: "Retención",
          value: "tipoValorNombre",
          sortable: false,
          align: "start",
        },
        {
          text: "Número",
          value: "numero",
          sortable: false,
          align: "start",
        },
        {
          text: "Importe",
          value: "importe",
          sortable: false,
          align: "end",
        },
      ],
      valoresList: [],
      headersValores: [
        {
          text: "Valor",
          value: "tipoValorNombre",
          sortable: false,
          align: "start",
        },
        {
          text: "Número",
          value: "numero",
          sortable: false,
          align: "start",
        },
        {
          text: "Fecha",
          value: "fechaValor",
          sortable: false,
          align: "start",
        },
        {
          text: "Banco",
          value: "bancoNombre",
          sortable: false,
          align: "start",
        },
        {
          text: "Importe",
          value: "importe",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getComprobanteProveedorById: "proveedores/getComprobanteProveedorById",
    }),
    async setDetalleComprobante() {
      const idComp = this.$route.params.movProvId;
      const response = await this.getComprobanteProveedorById(idComp);
      if (response.comprobantesCancelados.length != 0) {
        this.haveComprobCancel = true;
        this.comprobantesCanceladosList = response.comprobantesCancelados;
      }
      if (response.retenciones.length != 0) {
        this.haveListRetenciones = true;
        this.retencionesList = response.retenciones;
      }
      if (response.valores.length != 0) {
        this.haveListValores = true;
        this.valoresList = response.valores;
      }
      this.proveedor = response.cabecera.proveedorNombre;
      this.cuit = response.cabecera.cuit;
      this.tipoIva = response.cabecera.tipoIvaNombre;
      this.direccion = response.cabecera.direccion;
      this.modalidadPago = response.cabecera.modalidadPago;
      this.destinoPago = response.cabecera.detinoPago;
      this.tipoComprobante = response.cabecera.tcompNombre;
      this.comprobanteNum = response.cabecera.compNro;
      this.fecha = response.cabecera.fecha;
      this.fechaContable = response.cabecera.fechaContable;
      this.fechaVencimiento = response.cabecera.fechaVencimiento;
      this.fechaVencCAE = response.cabecera.fechaVtoCAE;
      this.tipoEmision = response.cabecera.tipoEmision;
      this.numeroCAE = response.cabecera.nroCAE;
      this.importeNeto = response.cabecera.neto;
      this.iva = response.cabecera.iva;
      this.percepcionIva = response.cabecera.percepcionIva;
      this.percepcionIIBB = response.cabecera.percepcionIB;
      this.impuestosInternos = response.cabecera.impuestosInternos;
      this.otrosConceptos = response.cabecera.otrosConceptos;
      this.importeTotal = response.cabecera.total;
    },
  },
};
</script>

<style scoped>
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}
.right-input >>> input {
  text-align: right;
}
.blue-text {
  color: blue;
  text-decoration: underline blue;
}
.btnDisabled {
  color: #100f0f99;
}
</style>